import { CURRENCIES } from '@/config/currencies';

export function useFormatters() {
  const { locales } = useI18n();
  const formatRating = (value) => {
    return Number.parseInt(value) === 10 ? value : Number.parseFloat(value).toFixed(1);
  };

  const formatCurrency = (amount, currencyCode, precision = 2) => {
    const currencyInfo = CURRENCIES.filter((currency) => currency.code === currencyCode);
    const amountValue = amount || 0;
    const currencySymbol = currencyInfo[0] ? currencyInfo[0].symbol : currencyCode;

    return `${currencySymbol}${Number.parseFloat(amountValue).toFixed(precision)}`;
  };

  const getLanguageFromUnderScoreIso = (lang) => {
    return lang?.replace(/_/g, '-');
  };

  const getLanguageFromIsoToUnderScore = (lang) => {
    return lang?.replace(/-/g, '_');
  };

  const getLocaleObjectByCode = (code) => {
    return locales?.value.find((l) => l.code === code);
  };

  const normalizeUrl = (url = '') => {
    return url
      ?.replace(/[\W_]+/g, '-')
      .toLowerCase()
      .trim();
  };

  return {
    useFormatter: {
      formatRating,
      formatCurrency,
      getLanguageFromUnderScoreIso,
      getLanguageFromIsoToUnderScore,
      getLocaleObjectByCode,
      normalizeUrl,
    },
  };
}
