export const useRouteStore = defineStore('routeStore', () => {
  const router = useRouter();

  const hwRoutes = router?.currentRoute?.value;

  // @NOTE: hwroute object keys needs to equal the one used on the composable useRoutes and routeStore
  const hwroute = ref({
    langUrl: null,
    propertyTypeUrl: null,
    continentUrl: null,
    countryUrl: null,
    cityUrl: null,
    cityPageNumber: null,
    propertyIdUrl: null,
    propertyNameUrl: null,
    areaUrl: null,
    regionUrl: null,
    districtUrl: null,
    districtPageNumber: null,
    chainUrl: null,
    cityFilterUrl: null,
    districtFilterUrl: null,
    genericUrl: null,
  });

  const propertyTypeKey = ref('hostel');

  const langUrls = ref({});

  const getPropertyType = computed(() => {
    return propertyTypeKey?.value;
  });

  const getRoute = computed(() => {
    return hwroute?.value;
  });

  const getAllRoutesFromLang = computed(() => () => {
    return langUrls?.value || null;
  });

  const getRouteFromLang = computed(() => (lang) => {
    return langUrls?.value?.[lang] || null;
  });

  const isHomePage = computed(() => {
    return !hwroute?.value.propertyTypeUrl && !hwroute?.value.genericUrl;
  });

  const isHomePageRoute = computed(() => {
    return hwRoutes?.name?.match(/^index/);
  });

  const isDistrictPage = computed(() => {
    return !!hwroute?.value.districtUrl;
  });

  const isCityPage = computed(() => {
    return !!hwroute?.value.cityUrl && !isDistrictPage;
  });

  const isPropertyPage = computed(() => {
    return hwroute?.value.propertyIdUrl > 0;
  });

  function setPropertyType(value) {
    propertyTypeKey.value = value;
  }

  function setRoute({
    langUrl,
    propertyTypeUrl,
    continentUrl,
    countryUrl,
    cityUrl,
    cityPageNumber,
    propertyIdUrl,
    propertyNameUrl,
    areaUrl,
    regionUrl,
    districtUrl,
    districtPageNumber,
    chainUrl,
    cityFilterUrl,
    districtFilterUrl,
    genericUrl,
  }) {
    hwroute.value = {
      langUrl,
      propertyTypeUrl: propertyTypeUrl || null,
      continentUrl: continentUrl || null,
      countryUrl: countryUrl || null,
      cityUrl: cityUrl || null,
      cityPageNumber: cityPageNumber || null,
      propertyIdUrl: propertyIdUrl || null,
      propertyNameUrl: propertyNameUrl || null,
      areaUrl: areaUrl || null,
      regionUrl: regionUrl || null,
      districtUrl: districtUrl || null,
      districtPageNumber: districtPageNumber || null,
      chainUrl: chainUrl || null,
      cityFilterUrl: cityFilterUrl || null,
      districtFilterUrl: districtFilterUrl || null,
      genericUrl: genericUrl || null,
    };
  }

  function setAllRoutesByLang(langUrls) {
    langUrls.value = langUrls;
  }

  function setRoutesByLang(lang, langUrl) {
    langUrls.value[lang] = langUrl;
  }

  return {
    hwroute,
    propertyTypeKey,
    langUrls,
    getPropertyType,
    getRoute,
    getAllRoutesFromLang,
    getRouteFromLang,
    isHomePage,
    isHomePageRoute,
    isCityPage,
    isDistrictPage,
    isPropertyPage,
    setPropertyType,
    setRoute,
    setAllRoutesByLang,
    setRoutesByLang,
  };
});
