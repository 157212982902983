import { COOKIECONSENTLINKS } from '@/config/cookie-consent-links.js';
import t_SLUGPROPERTYTYPEBNB from '@/config/propertyTypesTrads/t_SLUGPROPERTYTYPEBNB';
import t_SLUGPROPERTYTYPEHOSTELS from '@/config/propertyTypesTrads/t_SLUGPROPERTYTYPEHOSTELS';
import t_SLUGPROPERTYTYPEHOTELS from '@/config/propertyTypesTrads/t_SLUGPROPERTYTYPEHOTELS';
import { findPropertyTypeByKey } from '@/utils/propertyTypes';

export function useUrls() {
  const runtimeConfig = useRuntimeConfig();
  const { useFormatter } = useFormatters();
  const route = useRoute();
  const {
    t,
    locale,
    locales,
  } = useI18n();

  const testingStore = useTestingStore();
  const routeStore = useRouteStore();

  const envData = runtimeConfig?.public;

  const localeObj = useFormatter.getLocaleObjectByCode(locale?.value);

  const isRedirectCountryEnabled = testingStore.hasEnabledFeature('web_redirectCountry');
  const isRedirectCityEnabled = testingStore.hasEnabledFeature('web_redirectCity');
  const isRedirectPropertyEnabled = testingStore.hasEnabledFeature('web_redirectProperty');
  const isRedirectDistrictEnabled = testingStore.hasEnabledFeature('web_redirectDistrict');
  const isRedirectAreaEnabled = testingStore.hasEnabledFeature('web_redirectArea');
  const isRedirectRegionEnabled = testingStore.hasEnabledFeature('web_redirectRegion');
  const isRedirectDynamicSearchEnabled = testingStore.hasEnabledFeature('web_redirectDynamicSearch');
  const isRedirectDynamicPropertyEnabled = testingStore.hasEnabledFeature('web_redirectDynamicProperty');
  const isRedirectDynamicMyAccountEnabled = testingStore.hasEnabledFeature('web_redirectDynamicMyAccount');

  const staticSuffix = envData.STATIC_URL;
  const dynamicSuffix = envData.DYNAMIC_URL;

  const transcodesPropertyTypes = {
    t_PROPERTYTYPEHOSTELS: t_SLUGPROPERTYTYPEHOSTELS,
    t_PROPERTYTYPEHOTELS: t_SLUGPROPERTYTYPEHOTELS,
    t_PROPERTYTYPEBNBS: t_SLUGPROPERTYTYPEBNB,
  };
  const getPropertyTypeForHref = (propertyTypeTranscode, propertyTypeSlug, langCode) => {
    return transcodesPropertyTypes[propertyTypeTranscode]?.[langCode] || propertyTypeSlug;
  };

  const getStaticBaseURL = () => {
    return `${envData.BASE_URL_PROTOCOL}${envData.BASE_URL_DIVIDER}${envData.DEFAULT_BASE_URL}${
      envData[`BASE_URL_${locale?.value?.toUpperCase()}`]
    }${staticSuffix}`;
  };

  const getDynamicBaseURL = () => {
    return `${envData.BASE_URL_PROTOCOL}${envData.BASE_URL_DIVIDER}${envData.DEFAULT_BASE_URL}${dynamicSuffix}${
      envData[`BASE_URL_${locale?.value?.toUpperCase()}`]
    }/`;
  };

  const getSpwaLangBaseUrl = () => {
    return `${envData.BASE_URL_PROTOCOL}${envData.BASE_URL_DIVIDER}${envData[localeObj?.rwdUrl]}${envData.DOMAIN_URL}/st/`;
  };

  const getPwaLangBaseUrl = () => {
    return `${envData.BASE_URL_PROTOCOL}${envData.BASE_URL_DIVIDER}${envData[localeObj?.rwdUrl]}${envData.DOMAIN_URL}/`;
  };

  const startUrl = getStaticBaseURL();
  const startUrlDynamic = getDynamicBaseURL();
  const startSpwaUrl = getSpwaLangBaseUrl();
  const startPwaUrl = getPwaLangBaseUrl();

  const getBaseURL = () => {
    return getStaticBaseURL();
  };

  const buildExtraParams = (params, flag) => {
    const qs = route.query?.dev && flag ? ['dev=1'] : [];

    if (params && Object.keys(params).length > 0) {
      const queryParams = new URLSearchParams(params);
      qs.push(queryParams.toString());
    }
    return qs.length ? `?${qs.join('&')}` : '';
  };

  const buildPageNumbers = (pageNumber) => {
    let pageNumbers = '';

    if (pageNumber && pageNumber > 1) {
      pageNumbers = `p/${pageNumber}/`;
    }

    return pageNumbers;
  };

  const getCanonicalGenericPageUrl = (urlStructure) => {
    routeStore.setRoute({ genericUrl: urlStructure });
    return `${startUrl}${urlStructure}/`;
  };

  const getLanguageUrl = (lang) => {
    return `${envData.BASE_URL_PROTOCOL}${envData.BASE_URL_DIVIDER}${envData.DEFAULT_BASE_URL}${
      envData[lang?.baseUrl]
    }${staticSuffix}`;
  };

  const getWorldPageUrl = (locationData, extraParams) => {
    // @TODO: remove the 3rd value - attribution when all migration is done
    const qs = buildExtraParams(extraParams, true);

    return `${startUrl}${locationData?.propertyTypeSlug}/${qs}`;
  };

  const getContinentPageUrl = (locationData, extraParams) => {
    // @TODO: remove the 3rd value - attribution when all migration is done
    const qs = buildExtraParams(extraParams, true);

    return `${startUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${qs}`;
  };

  const getCountryPageUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, isRedirectCountryEnabled);
    const finalUrl = isRedirectCountryEnabled ? startUrl : startSpwaUrl;

    return `${finalUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/${qs}`;
  };

  const getCityPageUrl = (locationData, extraParams, pageNr) => {
    const qs = buildExtraParams(extraParams, isRedirectCityEnabled);
    const finalUrl = isRedirectCityEnabled ? startUrl : startSpwaUrl;

    const pageNumbers = buildPageNumbers(pageNr);

    return `${finalUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/${locationData?.urlFriendlyCity}/${pageNumbers}${qs}`;
  };

  const getCityFilterUrl = (locationData, extraParams, pageNr) => {
    const qs = buildExtraParams(extraParams, isRedirectCityEnabled);
    const finalUrl = isRedirectCityEnabled ? startUrl : startSpwaUrl;

    const pageNumbers = buildPageNumbers(pageNr);

    return `${finalUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/${locationData?.urlFriendlyCity}/f/${locationData?.urlFilterSlug}/${pageNumbers}${qs}`;
  };

  const getCityPageUrlDynamic = (locationData, extraParams) => {
    const dynSearchPageParams = {
      q: locationData.searchString,
      country: locationData.countryName,
      city: locationData.cityName,
      type: 'city',
      id: locationData.cityId,
      from: locationData.dateCheckIn,
      to: locationData.dateCheckOut,
      guests: locationData.numOfGuests,
      page: 1,
    };

    const qs = buildExtraParams(
      {
        ...dynSearchPageParams,
        ...extraParams,
      },
      isRedirectDynamicSearchEnabled,
    );
    const finalUrl = isRedirectDynamicSearchEnabled ? `${startUrlDynamic}s` : `${startPwaUrl}pwa/wds/s`;

    return `${finalUrl}${qs}`;
  };

  // ### This function is slightly different from the one above, as it uses the locationData object to build the URL
  const getCityDynamicFabUrl = (locationData, extraParams) => {
    const humanReadableSearchQuery
      = locationData?.cityName && locationData?.countryName
        ? `${locationData.cityName}, ${locationData.countryName}`
        : `${locationData.urlFriendlyName}, ${locationData.urlFriendlyCountry}`;

    const dynSearchPageParams = {
      q: humanReadableSearchQuery,
      country: locationData.countryName,
      city: locationData.cityName,
      type: 'city',
      id: locationData.id,
    };

    const qs = buildExtraParams(
      {
        ...dynSearchPageParams,
        ...extraParams,
      },
      isRedirectDynamicSearchEnabled,
    );
    const finalUrl = isRedirectDynamicSearchEnabled ? `${startUrlDynamic}s` : `${startPwaUrl}pwa/wds/s`;

    return `${finalUrl}${qs}`;
  };

  const getDistrictPageUrl = (locationData, extraParams, pageNr) => {
    const qs = buildExtraParams(extraParams, isRedirectDistrictEnabled);
    const finalUrl = isRedirectDistrictEnabled ? startUrl : startSpwaUrl;

    const pageNumbers = buildPageNumbers(pageNr);

    return `${finalUrl}${locationData?.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/${locationData.urlFriendlyCity}/d/${locationData.urlFriendlyDistrict}/${pageNumbers}${qs}`;
  };

  const getRegionPageUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, isRedirectRegionEnabled);
    const finalUrl = isRedirectRegionEnabled ? startUrl : startSpwaUrl;

    return `${finalUrl}${locationData?.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/r/${locationData.urlFriendlyRegion}/${qs}`;
  };

  const getAreaPageUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, isRedirectAreaEnabled);
    const finalUrl = isRedirectAreaEnabled ? startUrl : startSpwaUrl;

    return `${finalUrl}${locationData?.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/a/${locationData.urlFriendlyArea}/${qs}`;
  };

  const getPropertyPageUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, isRedirectPropertyEnabled);
    const finalUrl = isRedirectPropertyEnabled ? startUrl : startSpwaUrl;

    return `${finalUrl}${locationData?.propertyTypeSlug}/p/${locationData.propertyId}/${locationData.urlFriendlyProperty}/${qs}`;
  };

  const getPropertyPageUrlDynamic = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, isRedirectDynamicPropertyEnabled);
    const finalUrl = isRedirectDynamicPropertyEnabled
      ? `${startUrlDynamic}p/${locationData.propertyId}/${locationData.urlFriendlyProperty}/`
      : `${startPwaUrl}pwa/hosteldetails.php/${locationData?.urlFriendlyProperty}/${locationData?.urlFriendlyCity}/${locationData?.propertyId}/`;

    return `${finalUrl}${qs}`;
  };

  const getGenericPageUrl = (urlStructure, extraParams) => {
    const qs = buildExtraParams(extraParams, true);

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getMyAccountPageUrlDynamic = (accountArea, extraParams) => {
    const qs = buildExtraParams(extraParams, isRedirectDynamicMyAccountEnabled);
    const finalUrl = isRedirectDynamicMyAccountEnabled
      ? `${startUrlDynamic}/myaccount/${accountArea}/`
      : `${startPwaUrl}pwa/${accountArea || ''}`;

    return `${finalUrl}${qs ? `?${qs}` : ''}`;
  };

  const getLogoutPageUrlDynamic = (extraParams) => {
    const qs = buildExtraParams(extraParams, isRedirectDynamicMyAccountEnabled);
    const finalUrl = isRedirectDynamicMyAccountEnabled
      ? `${startUrlDynamic}myaccount/logout/`
      : `${startPwaUrl}pwa/logout/`;

    return `${finalUrl}${qs ? `?${qs}` : ''}`;
  };

  const getHrefLangUrlForGenericPage = (localeObj, urlStructure) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    const actualUrlStructure = urlStructure ? `${urlStructure}/` : '';
    return `${mainHrefLangUrl}${actualUrlStructure}`;
  };

  const getHrefLangUrlForWorldwide = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/`;
  };

  const getHrefLangUrlForContinent = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/`;
  };

  const getHrefLangUrlForCountry = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/`;
  };

  const getHrefLangUrlForArea = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/a/${locationData?.urlFriendlyArea}/`;
  };

  const getHrefLangUrlForRegion = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/r/${locationData?.urlFriendlyRegion}/`;
  };

  const getHrefLangUrlForCity = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/${locationData?.urlFriendlyCity}/`;
  };

  const getHrefLangUrlForDistrict = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/${locationData?.urlFriendlyContinent}/${locationData?.urlFriendlyCountry}/${locationData?.urlFriendlyCity}/d/${locationData?.urlFriendlyDistrict}`;
  };

  const getHrefLangUrlForProperty = (localeObj, locationData) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}${locationData?.propertyTypeSlug}/p/${locationData?.propertyId}/${locationData?.urlFriendlyProperty}/`;
  };

  const getHrefLangUrlForHomepage = (localeObj) => {
    const mainHrefLangUrl = getLanguageUrl(localeObj);
    return `${mainHrefLangUrl}`;
  };

  const getReplacementForPropertyTypeFromUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams);
    // Because English doesn't appear on the URL
    const sliceNumber = locale?.value === 'en' ? 1 : 2;
    const currentPath = route.path
      .split('/')
      .filter((param) => param)
      .slice(sliceNumber);
    const newPath = [locationData.propertyTypeSlug, ...currentPath].join('/');

    return `${startUrl}${newPath}/${qs}`;
  };

  const getCookiePolicyUrl = () => {
    const qs = buildExtraParams(null);
    const urlStructure = COOKIECONSENTLINKS.COOKIESPOLICY.STATICWEB;

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getSecurityPrivacyUrl = () => {
    const qs = buildExtraParams(null);
    const urlStructure = COOKIECONSENTLINKS.SECURITYPRIVACY.STATICWEB;

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getCouponsUrl = (urlStructure, extraParams) => {
    const qs = buildExtraParams(extraParams);

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getVouchersUrl = (urlStructure, extraParams) => {
    const qs = buildExtraParams(extraParams);

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getTermsAndConditionsUrl = () => {
    const qs = buildExtraParams(null);
    const urlStructure = COOKIECONSENTLINKS.HOSTELTERMSANDCONDITIONS.STATICWEB;

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getGuaranteePageUrl = () => {
    const qs = buildExtraParams(null);
    const urlStructure = COOKIECONSENTLINKS.GUARANTEE.STATICWEB;

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getReviewGuidelinesPageUrl = () => {
    const qs = buildExtraParams(null);
    const urlStructure = COOKIECONSENTLINKS.REVIEWGUIDELINES.STATICWEB;

    return `${startUrl}${urlStructure}/${qs}`;
  };

  const getPropertySignUpUrl = () => {
    let finalUrl = t('t_HOMEURLHOSTELSIGNUP');

    if (['dev', 'test'].includes(envData.RELEASE_ENV)) {
      finalUrl = t('t_HOMETESTURLHOSTELSIGNUP');
    } else if (envData.RELEASE_ENV === 'staging') {
      finalUrl = t('t_HOMESTAGINGURLHOSTELSIGNUP');
    }

    return `${finalUrl}`;
  };

  const generateHrefLangAlternates = (pageUrlStructure) => {
    const hrefLangAlternates = [];

    locales?.value?.forEach((lang) => {
      const actualUrl = getHrefLangUrlForGenericPage(lang, pageUrlStructure);
      routeStore.setRoutesByLang(lang?.code, actualUrl);
      hrefLangAlternates.push({
        hid: `alternate-${lang?.lang}`,
        rel: 'alternate',
        hreflang: lang?.lang,
        href: actualUrl,
      });
      if (lang?.isCatchallLocale) {
        hrefLangAlternates.push({
          hid: 'alternate-x-default',
          rel: 'alternate',
          hreflang: 'x-default',
          href: actualUrl,
        });
      }
    });
    return hrefLangAlternates;
  };

  const generateHrefLangForWorldWide = (urlTranslations, propertyTypeKey) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    if (urlTranslations && urlTranslations?.[localeObj?.isoUnder]?.[propertyTypeKey]) {
      locales?.value?.forEach((lang) => {
        const actualLangUnderscoreIso = lang?.isoUnder;
        const actualPropertyType = urlTranslations[actualLangUnderscoreIso][propertyTypeKey];
        const propertySlugForHref = getPropertyTypeForHref(
          propertyTypeObj?.transcode,
          actualPropertyType,
          actualLangUnderscoreIso,
        );
        const actualUrl = getHrefLangUrlForWorldwide(lang, { propertyTypeSlug: propertySlugForHref });
        routeStore.setRoutesByLang(lang?.code, actualUrl);
        hrefLangAlternates.push({
          hid: `alternate-${lang?.lang}`,
          rel: 'alternate',
          hreflang: lang?.lang,
          href: actualUrl,
        });
        if (lang?.isCatchallLocale) {
          hrefLangAlternates.push({
            hid: 'alternate-x-default',
            rel: 'alternate',
            hreflang: 'x-default',
            href: actualUrl,
          });
        }
      });
    }
    return hrefLangAlternates;
  };

  const generateHrefLangForContinent = (urlTranslations, propertyTypeKey) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    if (urlTranslations && urlTranslations?.worldwide?.[localeObj?.isoUnder]?.[propertyTypeKey]) {
      locales?.value?.forEach((lang) => {
        const actualLangUnderscoreIso = lang?.isoUnder;
        const actualPropertyType = urlTranslations.worldwide[actualLangUnderscoreIso][propertyTypeKey];
        const actualContinent = urlTranslations.continent[actualLangUnderscoreIso];
        const propertySlugForHref = getPropertyTypeForHref(
          propertyTypeObj?.transcode,
          actualPropertyType,
          actualLangUnderscoreIso,
        );
        const actualUrl = getHrefLangUrlForContinent(lang, {
          propertyTypeSlug: propertySlugForHref,
          urlFriendlyContinent: actualContinent,
        });

        routeStore.setRoutesByLang(lang?.code, actualUrl);
        hrefLangAlternates.push({
          hid: `alternate-${lang?.lang}`,
          rel: 'alternate',
          hreflang: lang?.lang,
          href: actualUrl,
        });
        if (lang?.isCatchallLocale) {
          hrefLangAlternates.push({
            hid: 'alternate-x-default',
            rel: 'alternate',
            hreflang: 'x-default',
            href: actualUrl,
          });
        }
      });
    }
    return hrefLangAlternates;
  };

  const generateHrefLangForCountry = (urlTranslations, propertyTypeKey) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    if (urlTranslations && urlTranslations?.worldwide?.[localeObj?.isoUnder]?.[propertyTypeKey]) {
      locales?.value?.forEach((lang) => {
        const actualLangUnderscoreIso = lang?.isoUnder;
        const actualPropertyType = urlTranslations.worldwide[actualLangUnderscoreIso][propertyTypeKey];
        const actualContinent = urlTranslations.continent[actualLangUnderscoreIso];
        const actualCountry = urlTranslations.country[actualLangUnderscoreIso];
        const propertySlugForHref = getPropertyTypeForHref(
          propertyTypeObj?.transcode,
          actualPropertyType,
          actualLangUnderscoreIso,
        );
        const actualUrl = getHrefLangUrlForCountry(lang, {
          propertyTypeSlug: propertySlugForHref,
          urlFriendlyContinent: actualContinent,
          urlFriendlyCountry: actualCountry,
        });

        routeStore.setRoutesByLang(lang?.code, actualUrl);
        hrefLangAlternates.push({
          hid: `alternate-${lang?.lang}`,
          rel: 'alternate',
          hreflang: lang?.lang,
          href: actualUrl,
        });
        if (lang?.isCatchallLocale) {
          hrefLangAlternates.push({
            hid: 'alternate-x-default',
            rel: 'alternate',
            hreflang: 'x-default',
            href: actualUrl,
          });
        }
      });
    }
    return hrefLangAlternates;
  };

  const generateHrefLangForCity = (urlTranslations, propertyTypeKey) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    if (urlTranslations && urlTranslations?.worldwide?.[localeObj?.isoUnder]?.[propertyTypeKey]) {
      locales?.value?.forEach((lang) => {
        const actualLangUnderscoreIso = lang?.isoUnder;
        const actualPropertyType = urlTranslations.worldwide[actualLangUnderscoreIso][propertyTypeKey];
        const actualContinent = urlTranslations.continent[actualLangUnderscoreIso];
        const actualCountry = urlTranslations.country[actualLangUnderscoreIso];
        const actualCity = urlTranslations.city[actualLangUnderscoreIso];

        const propertySlugForHref = getPropertyTypeForHref(
          propertyTypeObj?.transcode,
          actualPropertyType,
          actualLangUnderscoreIso,
        );
        const actualUrl = getHrefLangUrlForCity(lang, {
          propertyTypeSlug: propertySlugForHref,
          urlFriendlyContinent: actualContinent,
          urlFriendlyCountry: actualCountry,
          urlFriendlyCity: actualCity,
        });

        routeStore.setRoutesByLang(lang?.code, actualUrl);
        hrefLangAlternates.push({
          hid: `alternate-${lang?.lang}`,
          rel: 'alternate',
          hreflang: lang?.lang,
          href: actualUrl,
        });
        if (lang?.isCatchallLocale) {
          hrefLangAlternates.push({
            hid: 'alternate-x-default',
            rel: 'alternate',
            hreflang: 'x-default',
            href: actualUrl,
          });
        }
      });
    }
    return hrefLangAlternates;
  };

  const generateHrefLangForDistrict = (urlTranslations, propertyTypeKey) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    locales?.value?.forEach((lang) => {
      const actualLangUnderscoreIso = lang?.isoUnder;
      const actualPropertyType = urlTranslations.worldwide[actualLangUnderscoreIso][propertyTypeKey];
      const actualContinent = urlTranslations.continent[actualLangUnderscoreIso];
      const actualCountry = urlTranslations.country[actualLangUnderscoreIso];
      const actualCity = urlTranslations.city[actualLangUnderscoreIso];
      const actualDistrict = urlTranslations.district[actualLangUnderscoreIso];

      const propertySlugForHref = getPropertyTypeForHref(
        propertyTypeObj?.transcode,
        actualPropertyType,
        actualLangUnderscoreIso,
      );
      const actualUrl = getHrefLangUrlForDistrict(lang, {
        propertyTypeSlug: propertySlugForHref,
        urlFriendlyContinent: actualContinent,
        urlFriendlyCountry: actualCountry,
        urlFriendlyCity: actualCity,
        urlFriendlyDistrict: actualDistrict,
      });

      routeStore.setRoutesByLang(lang?.code, actualUrl);
      hrefLangAlternates.push({
        hid: `alternate-${lang?.lang}`,
        rel: 'alternate',
        hreflang: lang?.lang,
        href: actualUrl,
      });
      if (lang?.isCatchallLocale) {
        hrefLangAlternates.push({
          hid: 'alternate-x-default',
          rel: 'alternate',
          hreflang: 'x-default',
          href: actualUrl,
        });
      }
    });
    return hrefLangAlternates;
  };

  const generateHrefLangForArea = (urlTranslations, propertyTypeKey) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    if (urlTranslations && urlTranslations?.worldwide?.[localeObj?.isoUnder]?.[propertyTypeKey]) {
      locales?.value?.forEach((lang) => {
        const actualLangUnderscoreIso = lang?.isoUnder;
        const actualPropertyType = urlTranslations.worldwide[actualLangUnderscoreIso][propertyTypeKey];
        const actualContinent = urlTranslations.continent[actualLangUnderscoreIso];
        const actualCountry = urlTranslations.country[actualLangUnderscoreIso];
        const actualArea = urlTranslations.area[actualLangUnderscoreIso];

        const propertySlugForHref = getPropertyTypeForHref(
          propertyTypeObj?.transcode,
          actualPropertyType,
          actualLangUnderscoreIso,
        );
        const actualUrl = getHrefLangUrlForArea(lang, {
          propertyTypeSlug: propertySlugForHref,
          urlFriendlyContinent: actualContinent,
          urlFriendlyCountry: actualCountry,
          urlFriendlyArea: actualArea,
        });

        routeStore.setRoutesByLang(lang?.code, actualUrl);
        hrefLangAlternates.push({
          hid: `alternate-${lang?.lang}`,
          rel: 'alternate',
          hreflang: lang?.lang,
          href: actualUrl,
        });
        if (lang?.isCatchallLocale) {
          hrefLangAlternates.push({
            hid: 'alternate-x-default',
            rel: 'alternate',
            hreflang: 'x-default',
            href: actualUrl,
          });
        }
      });
    }
    return hrefLangAlternates;
  };

  const generateHrefLangForRegion = (urlTranslations, propertyTypeKey) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    if (urlTranslations && urlTranslations?.worldwide?.[localeObj?.isoUnder]?.[propertyTypeKey]) {
      locales?.value?.forEach((lang) => {
        const actualLangUnderscoreIso = lang?.isoUnder;
        const actualPropertyType = urlTranslations.worldwide[actualLangUnderscoreIso][propertyTypeKey];
        const actualContinent = urlTranslations.continent[actualLangUnderscoreIso];
        const actualCountry = urlTranslations.country[actualLangUnderscoreIso];
        const actualRegion = urlTranslations.region[actualLangUnderscoreIso];

        const propertySlugForHref = getPropertyTypeForHref(
          propertyTypeObj?.transcode,
          actualPropertyType,
          actualLangUnderscoreIso,
        );
        const actualUrl = getHrefLangUrlForRegion(lang, {
          propertyTypeSlug: propertySlugForHref,
          urlFriendlyContinent: actualContinent,
          urlFriendlyCountry: actualCountry,
          urlFriendlyRegion: actualRegion,
        });

        routeStore.setRoutesByLang(lang?.code, actualUrl);
        hrefLangAlternates.push({
          hid: `alternate-${lang?.lang}`,
          rel: 'alternate',
          hreflang: lang?.lang,
          href: actualUrl,
        });
        if (lang?.isCatchallLocale) {
          hrefLangAlternates.push({
            hid: 'alternate-x-default',
            rel: 'alternate',
            hreflang: 'x-default',
            href: actualUrl,
          });
        }
      });
    }
    return hrefLangAlternates;
  };

  const generateHrefLangForProperty = (urlTranslations, propertyTypeKey, propertyId) => {
    const hrefLangAlternates = [];
    const propertyTypeObj = findPropertyTypeByKey(propertyTypeKey);

    if (urlTranslations && urlTranslations?.worldwide?.[localeObj?.isoUnder]?.[propertyTypeKey]) {
      locales?.value?.forEach((lang) => {
        const actualLangUnderscoreIso = lang?.isoUnder;
        const actualPropertyType = urlTranslations.worldwide[actualLangUnderscoreIso][propertyTypeKey];
        const actualProperty = urlTranslations.property[actualLangUnderscoreIso];

        const propertySlugForHref = getPropertyTypeForHref(
          propertyTypeObj?.transcode,
          actualPropertyType,
          actualLangUnderscoreIso,
        );
        const actualUrl = getHrefLangUrlForProperty(lang, {
          propertyTypeSlug: propertySlugForHref,
          propertyId,
          urlFriendlyProperty: actualProperty,
        });

        routeStore.setRoutesByLang(lang?.code, actualUrl);
        hrefLangAlternates.push({
          hid: `alternate-${lang?.lang}`,
          rel: 'alternate',
          hreflang: lang?.lang,
          href: actualUrl,
        });
        if (lang?.isCatchallLocale) {
          hrefLangAlternates.push({
            hid: 'alternate-x-default',
            rel: 'alternate',
            hreflang: 'x-default',
            href: actualUrl,
          });
        }
      });
    }
    return hrefLangAlternates;
  };

  return {
    useUrl: {
      getBaseURL,
      getStaticBaseURL,
      getDynamicBaseURL,
      getSpwaLangBaseUrl,
      getPwaLangBaseUrl,
      getCanonicalGenericPageUrl,
      getLanguageUrl,
      getWorldPageUrl,
      getContinentPageUrl,
      getCountryPageUrl,
      getRegionPageUrl,
      getAreaPageUrl,
      getCityPageUrl,
      getCityFilterUrl,
      getCityPageUrlDynamic,
      getCityDynamicFabUrl,
      getDistrictPageUrl,
      getPropertyPageUrl,
      getPropertyPageUrlDynamic,
      getGenericPageUrl,
      getMyAccountPageUrlDynamic,
      getLogoutPageUrlDynamic,
      getHrefLangUrlForGenericPage,
      getHrefLangUrlForHomepage,
      getHrefLangUrlForContinent,
      getHrefLangUrlForCountry,
      getHrefLangUrlForArea,
      getHrefLangUrlForRegion,
      getHrefLangUrlForCity,
      getHrefLangUrlForWorldwide,
      getHrefLangUrlForProperty,
      getReplacementForPropertyTypeFromUrl,
      generateHrefLangAlternates,
      generateHrefLangForWorldWide,
      generateHrefLangForContinent,
      generateHrefLangForCountry,
      generateHrefLangForCity,
      generateHrefLangForDistrict,
      generateHrefLangForArea,
      generateHrefLangForRegion,
      generateHrefLangForProperty,
      getCookiePolicyUrl,
      getSecurityPrivacyUrl,
      getTermsAndConditionsUrl,
      getGuaranteePageUrl,
      getReviewGuidelinesPageUrl,
      getCouponsUrl,
      getVouchersUrl,
      getPropertySignUpUrl,
    },
  };
}
