import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/build/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/build/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/build/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_ghbUAjaD3n from "/build/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import _01_cookie_consent_client_63fW3EDrPs from "/build/src/plugins/01.cookie-consent.client.js";
import _01_error_handler_ZVpV9yZcyH from "/build/src/plugins/01.error-handler.js";
import _02_sentry_client_JYW8U5S16Z from "/build/src/plugins/02.sentry.client.js";
import _04_urls_TROQtJ9N1o from "/build/src/plugins/04.urls.js";
import _05_optimizely_DFj9RLbBTy from "/build/src/plugins/05.optimizely.js";
import _06_api_P0Z8Jj8nQF from "/build/src/plugins/06.api.js";
import _97_google_tag_manager_client_nmHju2YATE from "/build/src/plugins/97.google-tag-manager.client.js";
import _98_segment_client_W1AZZv8Jub from "/build/src/plugins/98.segment.client.js";
import _99_tracking_client_hVpP3ZCFNw from "/build/src/plugins/99.tracking.client.js";
import mq_pVdvEotxbP from "/build/src/plugins/mq.js";
import vue3_touch_events_VzLX4C4dl3 from "/build/src/plugins/vue3-touch-events.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_ghbUAjaD3n,
  _01_cookie_consent_client_63fW3EDrPs,
  _01_error_handler_ZVpV9yZcyH,
  _02_sentry_client_JYW8U5S16Z,
  _04_urls_TROQtJ9N1o,
  _05_optimizely_DFj9RLbBTy,
  _06_api_P0Z8Jj8nQF,
  _97_google_tag_manager_client_nmHju2YATE,
  _98_segment_client_W1AZZv8Jub,
  _99_tracking_client_hVpP3ZCFNw,
  mq_pVdvEotxbP,
  vue3_touch_events_VzLX4C4dl3
]