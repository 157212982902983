<script setup>
import config from '@/config/seo-schema.js';

const { useFormatter } = useFormatters();
const { locale } = useI18n();

const langCode = useFormatter.getLocaleObjectByCode(locale?.value);

await preloadComponents(['WdsSetup', 'CommonHwSearchForm']);

function buildSocialMediaLinks() {
  const shouldShowLinkRule1 = langCode?.isoUnder === 'zh_CN' && config.links[langCode?.isoUnder].weibo;
  const shouldShowLinkRule2 = langCode?.isoUnder === 'ko_KR' && config.links[langCode?.isoUnder].naver;
  const shouldShowLinkRule3 = langCode?.isoUnder !== 'zh_CN' && config.links[config.defaultLanguageCode].facebook;
  const shouldShowLinkRule4
    = langCode?.isoUnder !== 'zh_CN'
    && (config.links[langCode?.isoUnder] && config.links[langCode?.isoUnder].twitter
      ? config.links[langCode?.isoUnder].twitter
      : config.links[config.defaultLanguageCode].twitter);
  const shouldShowLinkRule5 = langCode?.isoUnder !== 'zh_CN' && config.links[config.defaultLanguageCode].youtube;
  const shouldShowLinkRule6 = langCode?.isoUnder !== 'zh_CN' && config.links[config.defaultLanguageCode].instagram;

  return [
    shouldShowLinkRule1,
    shouldShowLinkRule2,
    shouldShowLinkRule3,
    shouldShowLinkRule4,
    shouldShowLinkRule5,
    shouldShowLinkRule6,
    config.links[config.defaultLanguageCode].linkedin,
    config.links[config.defaultLanguageCode].crunchbase,
  ].filter(Boolean);
}

useHead(() => ({
  link: [
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/raw/upload/f_auto,q_auto/wds/logos/brand/hw-orange.svg',
    },
  ],
}));

useSchemaOrg([
  defineOrganization({
    name: 'Hostelworld',
    logo: config.logo,
    url: config.baseUrl,
    sameAs: buildSocialMediaLinks(),
  }),

  defineWebSite({
    name: 'Hostelworld',
    url: config.baseUrl,
    potentialAction: [
      defineSearchAction({
        'target': `${config.baseUrl}find/keywordsuggestions?search_keywords={search_term_string}&internalsearch=yes`,
        'query-input': 'required name=search_term_string',
      }),
    ],
  }),

  defineSoftwareApp({
    name: ref('Hostelworld'),
    operatingSystem: 'ANDROID',
    applicationCategory: 'https://schema.org/MobileApplication',
    downloadUrl: config.links[langCode?.isoUnder].android,
    aggregateRating: {
      '@type': 'AggregateRating',
      'ratingValue': config.rating.android.value,
      'ratingCount': config.rating.android.count,
    },
    offers: {
      '@type': 'Offer',
      'price': '0.00',
      'priceCurrency': '$',
    },
  }),

  defineSoftwareApp({
    name: ref('Hostelworld'),
    operatingSystem: 'IOS',
    applicationCategory: 'https://schema.org/MobileApplication',
    downloadUrl: config.links[langCode?.isoUnder].iOs,
    aggregateRating: {
      '@type': 'AggregateRating',
      'ratingValue': config.rating.iOs.value,
      'ratingCount': config.rating.iOs.count,
    },
    offers: {
      '@type': 'Offer',
      'price': '0.00',
      'priceCurrency': '$',
    },
  }),
]);
</script>

<template>
  <div>
    <CommonHwSvgLoader />
    <NuxtPwaManifest />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
